import { useTranslation } from 'react-i18next';

import { LocaleKeys } from '../../../locale';
import { Container, Description, Title } from './TrafficHasEnded.styled';
import { WarningIcon } from './WarningIcon';

export const TrafficHasEnded = () => {
    const { t } = useTranslation([LocaleKeys.MAIN]);

    return (
        <Container>
            <WarningIcon />
            <Title>{t('traffic.trafficTitle')}</Title>
            <Description>{t('traffic.trafficDesc')}</Description>
        </Container>
    );
};
