import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import {
  SDK_API_HOST,
  SDK_INTEGRATE_TOKEN,
  SDK_PLAYER_SESSION,
} from '../../constants';
import {
  TCreateSessionPayload,
  TCreateSessionResponse,
  TLoginInSDKPayload,
  TLoginInSDKResponse,
} from './types';

const indexesSdkApi = createApi({
  reducerPath: 'indexes-api',
  baseQuery: fetchBaseQuery({
    baseUrl: SDK_API_HOST + '/api/v1',
  }),
  endpoints: (builder) => ({
    createSession: builder.mutation<TCreateSessionResponse, string>({
      query: (token) => ({
        url: '/sessions/register',
        method: 'POST',
        body: {
          external_session: SDK_PLAYER_SESSION,
        } as TCreateSessionPayload,
        headers: {
          Authorization: `Bearer ${token}`,
          'x-token': SDK_INTEGRATE_TOKEN,
        },
      }),
    }),
    loginInSDK: builder.mutation<TLoginInSDKResponse, void>({
      query: () => ({
        url: '/authorization/login',
        method: 'POST',
        body: {
          login: 'develop',
          password: 'develop',
        } as TLoginInSDKPayload,
      }),
    }),
  }),
});

export const { useCreateSessionMutation, useLoginInSDKMutation } =
  indexesSdkApi;
export default indexesSdkApi;
