import EyeInvisibleOutlined from '@ant-design/icons/lib/icons/EyeInvisibleOutlined';
import EyeOutlined from '@ant-design/icons/lib/icons/EyeOutlined';
import LineChartOutlined from '@ant-design/icons/lib/icons/LineChartOutlined';
import SettingOutlined from '@ant-design/icons/lib/icons/SettingOutlined';
import styled from '@emotion/styled';
import { Dropdown } from 'antd';
import { MenuProps } from 'antd/lib/menu/menu';
import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useAuthContext } from '../../context/auth-context';
import { useIndexesContext } from '../../context/indexes-context';
import { useVideoContext } from '../../context/video-context';
import { LocaleKeys } from '../../locale';
import { MediaConstants, TTheme } from '../../styles';
import { boolToText, textToBool } from '../../utils/primitive';
import FullScreenIcon from '../icons/full-screen-icon';

const Wrapper = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  padding-right: 20px;
  border-right: 1px solid white;

  ${MediaConstants.MOBILE} {
    gap: 14px;
    padding-right: 14px;
  }
`;

const IconWrapper = styled('div')`
  width: 20px;
  height: 20px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => (theme as TTheme).COLORS.WHITE._100};
  font-size: 20px;
  transition: transform 0.3s ease-out;

  svg {
    width: 100%;
    object-fit: cover;
  }

  &.setting:hover {
    transform: rotate(90deg);
  }

  &.enterFull:hover {
    transform: scale(1.2);
  }

  &.exitFull:hover {
    transform: scale(0.8);
  }
`;

const MenuItemBlock = styled('div') <{ active: 'true' | 'false' }>`
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 8px;
  color: ${({ theme, active }) =>
    textToBool(active) ? (theme as TTheme).COLORS.ACCENT._400 : (theme as TTheme).COLORS.WHITE._100};
  transition: color 0.3s ease-out;
`;

export const ButtonsPanel: FC = () => {
  const wrapperRef = useRef<HTMLDivElement>(null);
  const { isFullScreen, handleEnterFullScreen, handleExitFullScreen } =
    useVideoContext();
  const { setViewGuarantee, viewGuarantee, analytics, setAnalytics } =
    useIndexesContext();

  const [canManageGuarantee, setCanManageGuarantee] = useState(false);

  const { linkData } = useAuthContext();

  const { t } = useTranslation([LocaleKeys.MAIN]);

  useEffect(() => {
    if (linkData) {
      if (linkData.videoSetting.viewingGuarantee) {
        setCanManageGuarantee(false);
        setViewGuarantee(true);
      } else {
        setCanManageGuarantee(true);
        setViewGuarantee(false);
      }
    }
  }, [linkData]);

  const handleChangeGuarantee = useCallback(() => {
    setViewGuarantee((prev) => !prev);
  }, []);

  const handleChangeAnalytics = useCallback(() => {
    setAnalytics((prev) => !prev);
  }, []);

  const handleFullScreenChange = useCallback(() => {
    if (isFullScreen) {
      handleExitFullScreen();
    } else {
      handleEnterFullScreen();
    }
  }, [isFullScreen]);

  const items: MenuProps['items'] = useMemo(
    () => [
      {
        key: 'guarantee',
        label: (
          <MenuItemBlock
            onClick={handleChangeGuarantee}
            active={boolToText(viewGuarantee)}
          >
            {viewGuarantee ? <EyeOutlined /> : <EyeInvisibleOutlined />}
            <span>{t('viewing.guarantee')}</span>
          </MenuItemBlock>
        ),
      },
      {
        key: 'analytics',
        label: (
          <MenuItemBlock
            onClick={handleChangeAnalytics}
            active={boolToText(analytics)}
          >
            <LineChartOutlined />
            <span>{t('analytics.details')}</span>
          </MenuItemBlock>
        ),
      },
    ],
    [viewGuarantee, analytics, t]
  );

  return (
    <Wrapper ref={wrapperRef}>
      <Dropdown
        menu={{
          items: items.filter((el) =>
            !canManageGuarantee ? el?.key !== 'guarantee' : true
          ),
        }}
        placement="top"
        trigger={['click']}
        forceRender
        getPopupContainer={() => wrapperRef.current as HTMLElement}
      >
        <IconWrapper className="setting">
          <SettingOutlined />
        </IconWrapper>
      </Dropdown>
      {'requestFullscreen' in document.body && (
        <IconWrapper
          onClick={handleFullScreenChange}
          className={isFullScreen ? 'exitFull' : 'enterFull'}
        >
          <FullScreenIcon />
        </IconWrapper>
      )}
    </Wrapper>
  );
};
