import LoadingOutlined from '@ant-design/icons/lib/icons/LoadingOutlined';
import styled from '@emotion/styled';
import { Button, Modal } from 'antd';
import { TFunction } from 'i18next';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useGetUserStatsByLinkQuery } from '../../api/view-api';
import { useAuthContext } from '../../context/auth-context';
import { LocaleKeys } from '../../locale';
import { DarkTheme, MediaConstants, TTheme } from '../../styles';
import { timeToHMS } from '../../utils/common';
import { boolToText } from '../../utils/primitive';
import { ModalTitle } from './modal.styles';

export const ModalWrapper = styled(Modal)`
  .ant-modal-content {
    max-width: 450px;
    max-height: 492px;
    background-color: ${({ theme }) => (theme as TTheme).COLORS.BLACK._400};
    border-radius: 20px;
    padding: 30px;

    ${MediaConstants.MOBILE} {
      max-width: 340px;
      max-height: 492px;
    }
  }

  .ant-modal-close {
    display: none;
  }
`;

const ContentWrapper = styled.div<{ loading: 'true' | 'false' }>`
  display: ${({ loading }) => (loading === 'true' ? 'flex' : 'block')};
  align-items: center;
  justify-content: center;
  min-height: ${({ loading }) => (loading === 'true' ? '200px' : 'unset')};
`;

const ModalButtons = styled('div')`
  display: flex;
  gap: 10px;
`;

const ModalButton = styled(Button)`
  background: ${({ theme }) => (theme as TTheme).COLORS.ACCENT._100};
  padding: 20px 20px 24px 20px;
  border-radius: 10px;
  font-size: 16px;
  font-weight: 500;
  line-height: 16px;

  &:first-of-type {
    padding: 20px 60px 24px 60px;

    ${MediaConstants.MOBILE} {
      padding: 16px 18px 18px 18px;
      font-size: 14px;
      font-weight: 500;
      line-height: 14px;
      text-align: left;
    }
  }

  ${MediaConstants.MOBILE} {
    padding: 16px 20px 18px 20px;
    font-size: 14px;
    font-weight: 500;
    line-height: 14px;
    text-align: left;
  }
`;

const ModalList = styled('ul')`
  padding: 0;
  list-style: none;
  margin: 0 0 30px;
`;

const ListTitle = styled('div')`
  margin-bottom: 10px;
  color: ${({ theme }) => (theme as TTheme).COLORS.WHITE._600};
  font-size: 18px;
  font-weight: 400;
  line-height: 23px;
  text-align: left;
`;

const ListElement = styled('li')`
  color: ${({ theme }) => (theme as TTheme).COLORS.WHITE._600};
  max-height: 18px;
  margin-bottom: 10px;

  &::before {
    display: inline-block;
    content: '';
    border-radius: 0.375rem;
    height: 8px;
    width: 8px;
    margin-right: 10px;
    background-color: ${({ theme }) => (theme as TTheme).COLORS.ACCENT._100};
  }
`;

const ListValue = styled('div')`
  display: inline;
  padding-left: 10px;
  color: ${({ theme }) => (theme as TTheme).COLORS.ACCENT._100};
`;

const LoadingIconWrapper = styled.div`
  font-size: 56px;
  padding: 20px;
`;

type TProps = {
  open: boolean;
  setOpen: (value: boolean) => void;
  watchAgain: () => void;
};

type WatchResultProps = {
  t: TFunction<string[]>;
};

const WatchResult: React.FC<WatchResultProps> = ({ t }) => {
  const { linkData } = useAuthContext();

  const [isStatsAdded, setIsStatsAdded] = useState(false);

  const { data, isFetching, isUninitialized } = useGetUserStatsByLinkQuery(
    {
      linkId: linkData?.id as string,
      page: 1,
      size: 5,
    },
    {
      skip: !linkData || !isStatsAdded,
    }
  );

  useEffect(() => {
    const timeout = setTimeout(() => {
      setIsStatsAdded(true);
    }, 4000);

    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, []);

  const resultHMS = timeToHMS(Number(data?.viewingDuration ?? 0));

  return (
    <ContentWrapper loading={boolToText(isFetching || isUninitialized)}>
      {isFetching || isUninitialized ? (
        <LoadingIconWrapper>
          <LoadingOutlined size={56} />
        </LoadingIconWrapper>
      ) : (
        <>
          <ModalList>
            <ListElement>
              {t('modal.count')}
              <ListValue>{data?.viewsCount}</ListValue>
            </ListElement>
            <ListElement>
              {t('modal.time')}
              <ListValue>
                {resultHMS.hours > 0
                  ? `${resultHMS.hours} ${t('timeMarks.hour')} `
                  : ''}
                {resultHMS.minutes > 0
                  ? `${resultHMS.minutes} ${t('timeMarks.min')} `
                  : ''}
                {resultHMS.seconds > 0
                  ? `${resultHMS.seconds} ${t('timeMarks.sec')}`
                  : ''}
              </ListValue>
            </ListElement>
            <ListElement>
              {t('modal.pause')}
              <ListValue>{data?.pauseClickCount}</ListValue>
            </ListElement>
          </ModalList>
          <ListTitle>{t('modal.metrics')}</ListTitle>
          <ModalList>
            <ListElement>
              {t('analytics.attention')}
              <ListValue>{data?.attentiveness}%</ListValue>
            </ListElement>
            <ListElement>
              {t('analytics.involvement')}
              <ListValue>{data?.engagement}%</ListValue>
            </ListElement>
            <ListElement>
              {t('analytics.happiness')}
              <ListValue>{data?.emotionality}%</ListValue>
            </ListElement>
            <ListElement>
              {t('analytics.amazement')}
              <ListValue>{data?.absent_mindedness}%</ListValue>
            </ListElement>
            <ListElement>
              {t('analytics.tiredness')}
              <ListValue>{data?.fatique}%</ListValue>
            </ListElement>
          </ModalList>
        </>
      )}
    </ContentWrapper>
  );
};

export const WatchResultModal: React.FC<TProps> = ({ open }) => {
  const { t } = useTranslation([LocaleKeys.MAIN]);

  const onClose = () => {
    window.location.reload();
  };

  return (
    <ModalWrapper open={open} footer={null} onClose={onClose}>
      <ModalTitle> {t('modal.thank')} </ModalTitle>
      <ListTitle>{t('modal.stats')}</ListTitle>
      <WatchResult t={t} />
      <ModalButtons>
        <ModalButton
          type="primary"
          style={{
            color: DarkTheme.COLORS.BLACK._400,
          }}
          onClick={onClose}
        >
          {t('modal.again')}
        </ModalButton>
        <ModalButton
          type="primary"
          ghost
          style={{
            color: DarkTheme.COLORS.WHITE._100,
          }}
          onClick={onClose}
        >
          {t('modal.close')}
        </ModalButton>
      </ModalButtons>
    </ModalWrapper>
  );
};
