import styled from '@emotion/styled';
import React, { FC } from 'react';

import { MediaConstants, TTheme } from '../../styles';
import { VideoPlayFilledIcon } from '../icons';

const Wrapper = styled('div')`
  display: flex;
  width: 88px;
  height: 38px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  pointer-events: none;
  background-color: rgba(0, 0, 0, 0.4);
  border-radius: 10px;

  ${MediaConstants.MOBILE} {
    width: 40px;
    height: 40px;

    svg {
      width: 14px;
    }
  }
`;

const VideoPlayIcon = styled(VideoPlayFilledIcon)`
  path {
    fill: ${({ theme }) => (theme as TTheme).COLORS.ACCENT._100};
  }
`;

export const PlayButton: FC = () => {
  return (
    <Wrapper>
      <VideoPlayIcon />
    </Wrapper>
  );
};
