import styled from '@emotion/styled';
import { Button, Modal } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { LocaleKeys } from '../../locale';
import { DarkTheme, MediaConstants, TTheme } from '../../styles';
import { ModalTitle } from './modal.styles';

const ModalWrapper = styled(Modal)`
  .ant-modal-content {
    max-width: 437px;
    max-height: 162px;
    background-color: ${({ theme }) => (theme as TTheme).COLORS.BLACK._400};
    border-radius: 20px;
    padding: 30px;

    ${MediaConstants.MOBILE} {
      max-width: 340px;
      max-height: 226px;
    }
  }

  .ant-modal-close {
    display: none;
  }
`;

const ModalButtons = styled('div')`
  display: flex;
  gap: 10px;

  ${MediaConstants.MOBILE} {
    flex-wrap: wrap;
  }
`;

const ModalButton = styled(Button)`
  background: ${({ theme }) => (theme as TTheme).COLORS.ACCENT._100};
  padding: 20px 18px 24px 18px;
  border-radius: 10px;
  font-size: 16px;
  font-weight: 500;
  line-height: 16px;

  ${MediaConstants.MOBILE} {
    padding: 18px 18px 20px 18px;
    width: 280px;
  }
`;

type TProps = {
  open: boolean;
  setOpen: (value: boolean) => void;
  continueWatch: () => void;
  watchFromStart: () => void;
};

export const ContinueWatchModal: React.FC<TProps> = ({
  open,
  setOpen,
  continueWatch,
  watchFromStart,
}) => {
  const { t } = useTranslation([LocaleKeys.MAIN]);

  return (
    <ModalWrapper open={open} onClose={() => setOpen(false)} footer={null}>
      <ModalTitle> {t('modal.end')} </ModalTitle>
      <ModalButtons>
        <ModalButton
          type="primary"
          style={{
            color: DarkTheme.COLORS.BLACK._400,
          }}
          onClick={continueWatch}
        >
          {t('modal.continue')}
        </ModalButton>
        <ModalButton
          type="primary"
          ghost
          style={{
            color: DarkTheme.COLORS.WHITE._100,
          }}
          onClick={() => {
            watchFromStart();
            setOpen(false);
          }}
        >
          {t('modal.repeat')}
        </ModalButton>
      </ModalButtons>
    </ModalWrapper>
  );
};
