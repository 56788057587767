// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `html {
  background-color: var(--theme-COLORS-BLACK-400);
}

body {
  margin: 0;
}

html, body, plai-player, .ant-app {
  width: 100%;
  height: 100%;
}

plai-player {
  display: block;
  font-family: "Inter", sans-serif;
}
plai-player, plai-player * {
  box-sizing: border-box;
}
plai-player::after, plai-player::before, plai-player *::after, plai-player *::before {
  box-sizing: border-box;
}
plai-player .ant-slider .ant-slider-dot:not(.ant-slider-dot-active) {
  background-color: var(--theme-COLORS-BLACK-500);
  border: 2px solid var(--theme-COLORS-BLACK-700);
}

.ant-notification-notice-message {
  font-weight: bold;
}`, "",{"version":3,"sources":["webpack://./src/styles/style.scss"],"names":[],"mappings":"AAAA;EACE,+CAAA;AACF;;AAEA;EACE,SAAA;AACF;;AAEA;EACE,WAAA;EACA,YAAA;AACF;;AAEA;EACE,cAAA;EACA,gCAAA;AACF;AACE;EACE,sBAAA;AACJ;AACI;EACE,sBAAA;AACN;AAGE;EACE,+CAAA;EACA,+CAAA;AADJ;;AAKA;EACE,iBAAA;AAFF","sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
