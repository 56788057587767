import styled from '@emotion/styled';
import { TTheme } from '../../styles';

export const ModalTitle = styled('div')`
      color: ${({ theme }) => (theme as TTheme).COLORS.WHITE._100};
      font-size: 20px;
      line-height: 24px;
      text-align: left;
      margin-bottom: 30px;
  `



