// INFO: chart.js требуется для работы @index5/face-detector
import 'chart.js';
import { FC, PropsWithChildren } from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';

import { persistedStore, store } from '../state';
import { ErrorBoundary } from './error-boundary';
import { ThemeService } from './theme-service';

export const Services: FC<PropsWithChildren> = ({ children }) => {
  return (
    <ErrorBoundary>
      <BrowserRouter>
        <Provider store={store}>
          <PersistGate persistor={persistedStore} loading={null}>
            <ThemeService>{children}</ThemeService>
          </PersistGate>
        </Provider>
      </BrowserRouter>
    </ErrorBoundary>
  );
};
