import { App } from 'antd';
import {
  Dispatch,
  FC,
  PropsWithChildren,
  RefObject,
  SetStateAction,
  createContext,
  memo,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';

import { LocaleKeys } from '../locale';
import { useAppDispatch } from '../state';
import { endVideoAction } from '../state/features/socket';
import { useAuthContext } from './auth-context';
import { useIndexesContext } from './indexes-context';
import { useStatContext } from './stat-context';

export type TVideoContext = {
  play: boolean;
  setPlay: Dispatch<SetStateAction<boolean>>;
  controlsVisible: boolean;
  setControlsVisible: Dispatch<SetStateAction<boolean>>;
  videoRef: RefObject<HTMLVideoElement>;
  togglePlay: () => void;
  onWrapperMouseMove: () => void;
  volume: number;
  setVolume: Dispatch<SetStateAction<number>>;
  isFullScreen: boolean;
  setFullScreen: Dispatch<SetStateAction<boolean>>;
  handleEnterFullScreen: () => void;
  handleExitFullScreen: () => void;
  videoPending: boolean;
  isCameraError: boolean;
  changeVideoTime: (value: number) => void;
  isWatchResultShowing: boolean;
  setWatchResultShowing: Dispatch<SetStateAction<boolean>>;
};

const context = createContext<TVideoContext>({
  play: false,
  setPlay: () => { },
  controlsVisible: true,
  setControlsVisible: () => { },
  videoRef: { current: null },
  togglePlay: () => { },
  onWrapperMouseMove: () => { },
  volume: 100,
  setVolume: () => { },
  isFullScreen: false,
  setFullScreen: () => { },
  handleEnterFullScreen: () => { },
  handleExitFullScreen: () => { },
  videoPending: false,
  isCameraError: false,
  changeVideoTime: () => { },
  isWatchResultShowing: false,
  setWatchResultShowing: () => { },
});

export const useVideoContext = () => useContext(context);

export const VideoContext: FC<PropsWithChildren> = memo(({ children }) => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const { message } = App.useApp();
  const dispatch = useAppDispatch();

  const {
    stoppedByIndexes,
    firstIndexAdded,
    setVideoWasStarted,
    viewGuarantee,
  } = useIndexesContext();

  const { handleCreateSessionStats } = useAuthContext();

  const { setStatStatus, onIntervalGoing, setPauseCount } = useStatContext();

  const userCameraErrorRef = useRef(false);

  const [controlsVisible, setControlsVisible] = useState(true);
  const [play, setPlay] = useState(false);
  const [isFullScreen, setFullScreen] = useState(false);
  const [volume, setVolume] = useState(100);
  const [videoPending, setVideoPending] = useState(false);

  const [isWatchResultShowing, setWatchResultShowing] = useState(false);

  const visibleTimeoutRef = useRef<NodeJS.Timeout>();

  const [isCameraError, setIsCameraError] = useState(false);

  const { t } = useTranslation([LocaleKeys.MAIN]);

  useEffect(() => {
    navigator.mediaDevices
      .getUserMedia({ video: true, audio: false })
      .catch(() => {
        if (!userCameraErrorRef.current) {
          userCameraErrorRef.current = true;
          setIsCameraError(true);
          void message.error(t('camera.error'));
        }
      });
  }, []);

  const togglePlay = useCallback(async () => {
    if (isCameraError) {
      void message.error(t('camera.error'));
      return;
    }

    if (stoppedByIndexes && firstIndexAdded && viewGuarantee) {
      return;
    }

    if (play) {
      if (videoRef.current) {
        void videoRef.current.pause();
      }
    } else {
      if (videoRef.current) {
        void videoRef.current.play();
      }
    }
  }, [
    play,
    stoppedByIndexes,
    firstIndexAdded,
    viewGuarantee,
    isCameraError,
    t,
  ]);

  useEffect(() => {
    const interval = setInterval(() => {
      const video = videoRef.current;

      if (video) {
        onIntervalGoing(video);
      }
    }, 1000);

    const video = videoRef.current;

    if (video) {
      const handleEnded = () => {
        setPlay(false);
        setStatStatus('ended');
        setWatchResultShowing(true);
        dispatch(endVideoAction());
      };

      const handlePause = () => {
        setPlay(false);
        setStatStatus('paused');
        if (!video.ended) {
          setPauseCount((prev) => prev + 1);
        }
      };

      const handlePlay = () => {
        setPlay(true);
        setVideoWasStarted((prev) => {
          if (!prev) {
            handleCreateSessionStats();
          }

          return true;
        });
        setStatStatus('recording');
      };

      const handleLoadStart = () => {
        setVideoPending(true);
      };

      const handleCanPlayThrough = () => {
        setVideoPending(false);
      };

      video.addEventListener('ended', handleEnded);
      video.addEventListener('pause', handlePause);
      video.addEventListener('play', handlePlay);
      video.addEventListener('loadstart', handleLoadStart);
      video.addEventListener('canplaythrough', handleCanPlayThrough);

      return () => {
        clearInterval(interval);
        video.removeEventListener('ended', handleEnded);
        video.removeEventListener('pause', handlePause);
        video.removeEventListener('play', handlePlay);
        video.removeEventListener('loadstart', handleLoadStart);
        video.removeEventListener('canplaythrough', handleCanPlayThrough);
      };
    }
  }, [onIntervalGoing, handleCreateSessionStats, dispatch]);

  const clearTimeOut = useCallback(() => {
    if (visibleTimeoutRef.current) {
      clearTimeout(visibleTimeoutRef.current);
    }
  }, []);

  const onWrapperMouseMove = useCallback(() => {
    if (!controlsVisible) {
      setControlsVisible(true);

      clearTimeOut();
    }
  }, [controlsVisible]);

  useEffect(() => {
    if (controlsVisible) {
      clearTimeOut();

      visibleTimeoutRef.current = setTimeout(() => {
        setControlsVisible(false);
      }, 3000);
    }
  }, [controlsVisible]);

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.volume = volume / 100;
    }
  }, [volume]);

  const handleEnterFullScreen = useCallback(() => {
    if (videoRef.current && videoRef.current.parentElement) {
      videoRef.current.parentElement.requestFullscreen().then(() => {
        setFullScreen(true);
      });

      videoRef.current.parentElement.addEventListener(
        'fullscreenchange',
        (e) => {
          if (document.fullscreenElement === e.target) {
            setFullScreen(true);
          } else {
            setFullScreen(false);
          }
        }
      );
    }
  }, []);

  const handleExitFullScreen = useCallback(async () => {
    try {
      await document.exitFullscreen();
      setFullScreen(false);
    } catch (e) { }
  }, []);

  const changeVideoTime = useCallback(
    (time: number) => {
      if ((time > 0 && viewGuarantee) || stoppedByIndexes) {
        return;
      }

      if (videoRef.current) {
        try {
          videoRef.current.currentTime = Math.min(
            Math.max(videoRef.current.currentTime + time, 0),
            videoRef.current.duration
          );
        } catch (e) {
          console.error(e);
        }
      }
    },
    [viewGuarantee, stoppedByIndexes]
  );

  useEffect(() => {
    const listener = (e: KeyboardEvent) => {
      switch (e.code) {
        case 'ArrowRight':
          changeVideoTime(5);
          return;
        case 'ArrowLeft':
          changeVideoTime(-5);
          return;
        case 'Space':
          void togglePlay();
          return;
        default:
          return;
      }
    };

    window.addEventListener('keydown', listener);

    return () => {
      window.removeEventListener('keydown', listener);
    };
  }, [changeVideoTime, togglePlay]);

  return (
    <context.Provider
      value={{
        play,
        setPlay,
        controlsVisible,
        setControlsVisible,
        videoRef,
        togglePlay,
        onWrapperMouseMove,
        volume,
        setVolume,
        isFullScreen,
        setFullScreen,
        handleEnterFullScreen,
        handleExitFullScreen,
        videoPending,
        isCameraError,
        changeVideoTime,
        isWatchResultShowing,
        setWatchResultShowing,
      }}
    >
      {children}
    </context.Provider>
  );
});
