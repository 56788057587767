import styled from '@emotion/styled';

import { TTheme } from '../../../styles';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 16px;
`;

export const Title = styled.h1`
  font-family: Inter;
  font-size: 32px;
  font-weight: 700;
  line-height: 35.2px;
  text-align: center;
  color: ${({ theme }) => (theme as TTheme).COLORS.WHITE._100};
  margin: 20px 0;
`;

export const Description = styled.p`
  font-family: Inter;
  font-size: 16px;
  font-weight: 500;
  line-height: 19.2px;
  text-align: center;
  color: ${({ theme }) => (theme as TTheme).COLORS.WHITE._600};
  margin: 0;
`;
