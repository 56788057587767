import Icon from '@ant-design/icons';

const WarningSVG = () => (
    <svg
        width="100"
        height="100"
        viewBox="0 0 100 100"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <rect width="100" height="100" rx="50" fill="#1E4141" />
        <path
            d="M55.01 20L54.2951 62.3512H46.6779L45.9629 20H55.01ZM50.5002 80C48.9786 80 47.677 79.4388 46.5954 78.3164C45.5138 77.1746 44.9821 75.8007 45.0005 74.1945C44.9821 72.6076 45.5138 71.253 46.5954 70.1306C47.677 68.9889 48.9786 68.418 50.5002 68.418C51.9852 68.418 53.2684 68.9889 54.3501 70.1306C55.4317 71.253 55.9817 72.6076 56 74.1945C55.9817 75.2588 55.7158 76.2361 55.2025 77.1263C54.7076 77.9971 54.0476 78.6938 53.2226 79.2162C52.3976 79.7387 51.4902 80 50.5002 80Z"
            fill="#39D5C9"
        />
    </svg>
);

export const WarningIcon = () => <Icon component={WarningSVG} />;
