import {
  BaseIndex,
  FaceDetector,
  FaceDetectorEvent,
} from '@index5/face-detector';
import {
  FC,
  Fragment,
  PropsWithChildren,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';

import {
  useCreateSessionMutation,
  useLoginInSDKMutation,
} from '../api/indexes-sdk-api';
import {
  SDK_API_HOST,
  SDK_API_TOKEN,
  SDK_INTEGRATE_TOKEN,
  SDK_PLAYER_SESSION,
} from '../constants';
import { useAuthContext } from '../context/auth-context';
import { useIndexesContext } from '../context/indexes-context';
import { useVideoContext } from '../context/video-context';
import { useAuthSelector } from '../state/features/auth/selectors';
import { IExportIndex } from '../types/index-core-types';
import { UserVideo } from './user-video';

const virtualCanvas = document.createElement('canvas');

export const IndexesService: FC<PropsWithChildren> = memo(({ children }) => {
  const { videoRef, isCameraError } = useVideoContext();

  const { userId, decodedToken } = useAuthSelector();

  const [indexesStarted, setIndexesStarted] = useState(false);

  const [createSession] = useCreateSessionMutation();

  const [loginInSDK] = useLoginInSDKMutation();

  const {
    personVideoRef,
    personCameraStarted,
    setStoppedByIndexes,
    stoppedByIndexes,
    setFirstIndexAdded,
    firstIndexAdded,
    viewGuarantee,
    videoWasStarted,
    setIndexData,
    indexData,
  } = useIndexesContext();

  const { linkData } = useAuthContext();

  const handleCreateSession = useCallback(
    async (token: string) => {
      try {
        if (!linkData) {
          return undefined;
        }

        const result = await createSession(token).unwrap();

        return result.id;
      } catch (e) {
        console.error(e);
      }
    },
    [linkData]
  );

  const handleLoginInSDK = useCallback(async () => {
    try {
      const result = await loginInSDK().unwrap();

      return result.token;
    } catch (e) {
      console.error(e);
    }
  }, []);

  useEffect(() => {
    if (
      personVideoRef.current &&
      personVideoRef.current.video &&
      personCameraStarted &&
      !isCameraError &&
      !indexesStarted &&
      userId &&
      decodedToken
    ) {
      const video = personVideoRef.current.video;

      setIndexesStarted(true);

      handleLoginInSDK().then((token) => {
        if (!token) {
          throw new Error("Can't start face detector, not access token");
        }

        handleCreateSession(token).then(() => {
          if (
            !SDK_API_HOST ||
            !SDK_API_TOKEN ||
            !SDK_INTEGRATE_TOKEN ||
            !SDK_PLAYER_SESSION
          ) {
            throw new Error(
              "Can't start face detector, not host or token or session"
            );
          }

          const detector = new FaceDetector({
            // url бекенда
            apiUrl: SDK_API_HOST,
            // токен для авторизации
            token: SDK_API_TOKEN,
            // ID сессии
            sessionId: SDK_PLAYER_SESSION,
            // Флаг отображения результатов на canvas
            drawResults: false,
            // video с камеры пользователя
            video,
            // canvas для отображения спроецированной маски
            canvas: virtualCanvas,
            processPayload: {
              username: `${decodedToken?.account.firstName}`,
              //@ts-ignore
              user_id: String(userId),
              camera: 'enabled',
              microphone: true,
            },
            integration_token: SDK_INTEGRATE_TOKEN,
          });

          void detector
            .on(
              FaceDetectorEvent.MembersIndexesUpdated,
              (members: Record<string, BaseIndex>) => {
                const index = members[userId];
                setIndexData({
                  attention: index ? index.attention : 0,
                  involvement: index ? index.involvement : 0,
                  amazement: index ? index.distraction : 0,
                  tiredness: index ? index.tiredness : 0,
                  happiness: index ? index.emotions : 0,
                } as IExportIndex);
              }
            )
            .on(FaceDetectorEvent.BaseIndexReceived, (index: BaseIndex) => {
              setIndexData({
                attention: index ? index.attention : 0,
                involvement: index ? index.involvement : 0,
                amazement: index ? index.distraction : 0,
                tiredness: index ? index.tiredness : 0,
                happiness: index ? index.emotions : 0,
              } as IExportIndex);
            })
            .create();
        });
      });
    }
  }, [
    personCameraStarted,
    isCameraError,
    indexesStarted,
    handleCreateSession,
    decodedToken,
    userId,
    handleLoginInSDK,
  ]);

  const increaseAttentionValue = useMemo(
    () => linkData?.videoSetting.increaseAttentionValue ?? 50,
    [linkData]
  );

  useEffect(() => {
    if (videoRef.current) {
      if (
        (indexData.attention || 0) < increaseAttentionValue &&
        viewGuarantee &&
        !videoRef.current.paused
      ) {
        videoRef.current.pause();
        setStoppedByIndexes(true);
      } else if (
        stoppedByIndexes &&
        videoRef.current.paused &&
        videoWasStarted &&
        (indexData.attention || 0) >= increaseAttentionValue
      ) {
        setStoppedByIndexes(false);
        void videoRef.current.play();
      }

      if (
        indexData.attention !== undefined &&
        indexData.attention !== 0 &&
        !firstIndexAdded
      ) {
        setFirstIndexAdded(true);
      }
    }
  }, [
    indexData,
    firstIndexAdded,
    viewGuarantee,
    stoppedByIndexes,
    videoWasStarted,
    increaseAttentionValue,
  ]);

  return (
    <Fragment>
      <UserVideo ref={personVideoRef} />
      {children}
    </Fragment>
  );
});
