type TThemeObjectType = {
  [key: string]: string | TThemeObjectType;
};

export function objToRootStyleProps(
  root: HTMLElement,
  obj: TThemeObjectType,
  parentKey?: string
) {
  (Object.keys(obj) as (keyof TThemeObjectType)[]).forEach((key) => {
    if (typeof obj[key] === 'object') {
      objToRootStyleProps(
        root,
        obj[key] as TThemeObjectType,
        parentKey ? parentKey + `-${key}` : (key as string)
      );
    } else {
      let valueKey = key as string;

      if (valueKey[0] === '_') {
        valueKey = valueKey.substring(1, valueKey.length);
      }

      root.style.setProperty(
        `${parentKey ? parentKey + '-' : '--'}${valueKey}`,
        obj[key] as string
      );
    }
  });
}
